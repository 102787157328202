<template>
	<header id="site-header" class="top-0 z-20 text-white bg-black lg:sticky" :class="{'min shadow-md': !isTop}">
		<div class="container xl:px-20 py-[15px] lg:py-[25px]">
			<div class="flex flex-wrap items-center justify-between lg:flex-nowrap">
				<div class="w-full lg:w-auto mb-4 lg:mb-0">
					<img class="logo mx-auto" src="@/assets/images/gsc-logo.jpg" alt="gsc-logo">
				</div>
				<div class="text-center w-full lg:w-auto">
					<div class="mb-3 leading-4">Arrange Your Appointment Today</div>
					<div class="mb-2 text-[30px] leading-[30px] font-semibold">
						<a href="tel:+6581132370">8113 2370</a>
					</div>
					<div class="sched lg:hidden leading-[135%]">
						<strong>Mon – Sat:</strong> 9am – 10pm<br><strong>Sunday & P.H:</strong> 9:30am – 10pm
					</div>
					<div class="sched hidden lg:block leading-[135%]">
						<strong>Mon – Sat:</strong> 9am – 10pm | <strong>Sunday & P.H:</strong> 9:30am – 10pm
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import IconPhone from '@/components/svg/IconPhone.vue'
export default {
	components: {
		IconPhone,
	},
	data () {
		return {
			isTop: true,
		};
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			if (window.pageYOffset >= 250) {
				if (this.isTop) this.isTop = false;
			} else if ( document.documentElement.scrollTop < 100 ) {
				if (!this.isTop) this.isTop = true;
			}
		},
	},
}
</script>

<style scoped>
.logo {
	height: 55px;
	width: auto;
}
@screen md {
	.logo {
		height: 70px;
	}
}
@screen lg {
	.logo {
		height: 80px;
	}
}
/* 
.call-us {
	padding-left: 50px;
	background: url('~@/assets/images/phone-icon.png') left center no-repeat;
	background-size: contain;
} */

/* @screen lg {
	#site-header {
		height: 5.5rem;
	}
}
@screen xl {
	#site-header {
		height: 7.5rem;
	}
} */

.header-inner {
	/* height: 120px; */
}
.logo {
	/* min-height: 3rem;
	max-height: 4rem;
	height: 48px; */
}
</style>
